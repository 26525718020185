import Auth from "@aws-amplify/auth";

export async function Init(): Promise<void> {
  const config = {
    stage: "gamma",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:f718cfb3-6e45-4a87-b0f7-d313411f0863",
    aws_user_pools_id: "us-east-1_HAQkMJ20m",
    aws_user_pools_web_client_id: "43e9n85ccmdgu8q287r203inu4",
    Auth: {
      identityPoolId: "us-east-1:f718cfb3-6e45-4a87-b0f7-d313411f0863",
      region: "us-east-1",
      userPoolId: "us-east-1_HAQkMJ20m",
      userPoolWebClientId: "43e9n85ccmdgu8q287r203inu4",
    },
    oauth: {
      domain: `whs-safer-gamma-na.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: "https://gamma.na.safer.whs.amazon.dev",
      redirectSignOut: "https://gamma.na.safer.whs.amazon.dev",
      scope: ["openid", "profile"],
      responseType: "code",
    },
  };
  Auth.configure(config);
}
